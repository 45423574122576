import React from "react"
import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import road_map from "../banner/static/market_drop.webp"
import * as classes from "./marketing_road_map_training.module.scss"

import loadable from "@loadable/component"
const Banner = loadable(() => import("../banner/banner"), {
  fallback: <div></div>,
})

export default function RoadMap() {
  return (
    <React.Fragment>
      <MainHeader />

      <Banner
        title={"Marketing Road Map Training"}
        backgroundImage={road_map}
      />

      <div className={classes.text_div}>
        <h2>Marketing Road Map Training</h2>
        <p>
          When you call upon us to provide your employees with the relevant
          training for marketing road mapping, we have a very detailed approach.
          Equipping your employees with the skillset to align consumer insights,
          know clients on a deeper level, and stay on brand while keeping the
          target audience in mind. We help them establish a cross department
          workflow as well as key marketing metrics, all while never losing
          focus of the ROI on your company’s campaigns. Modern marketing is
          reliant on the ability to generate good content, which is why we
          inculcate the need to prioritize content development. Marketing is the
          engine that drives most business today and we will make sure that
          yours is running smoothly. A marketing roadmap helps you organize
          chaos, define want to accomplish and what to do to get there and when.
          You will learn important skills such as alignment, communication and
          coordination. With the help of the skills you learn from this course
          you’ll be able to easily be able to stand out in a crowded online
          space.
        </p>
        <p>Marketing improvement training is for:</p>
        <ul>
          <li>Digital marketers</li>
          <li>Small business owners</li>
          <li>Influencers</li>
          <li>Online managers</li>
          <li>Graduates</li>
        </ul>
      </div>
      <MainFooter />
    </React.Fragment>
  )
}
